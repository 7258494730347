import React, { useEffect, useState } from "react"
import Select from "react-select"
import { formGroup, input } from "../ds/form.module.css"
import {
  PricingConfig,
  addOnsConfig,
  initialAddonsCostData,
  validateInputs,
  calculateTotalPrice,
  calculateAddOnsCost,
  PRODUCT_GROUP_DEFAULTS,
  PRODUCT_GROUP,
  AddOnConfig,
  PRICING_KEY,
} from "../utils/PricingUtils"

interface PricingCalculatorProps {
  config: PricingConfig
}

export function PricingCalculatorComponent({ config }: PricingCalculatorProps) {
  const [registrationCount, setRegistrationCount] = useState<number>(1000)
  const [photoCount, setPhotoCount] = useState<number>(1000)
  const [dayCount, setDayCount] = useState<number>(1)
  const [finalPrice, setFinalPrice] = useState(0)
  const [addOnCost, setAddOnCost] = useState(initialAddonsCostData)
  const [selectedAddOns, setSelectedAddOns] = useState<any>(addOnsConfig)
  const [quantities, setQuantities] = useState<Record<PRICING_KEY, number>>({
    ...initialAddonsCostData,
    [PRICING_KEY.PHOTOGRAPHERCOST]: 1,
    [PRICING_KEY.PROMOTER]: 1,
    [PRICING_KEY.SPOT_EDITOR]: 1,
  })
  const [copySuccess, setCopySuccess] = useState(false)

  useEffect(() => {
    setPhotoCount(registrationCount * 3)
  }, [registrationCount])

  useEffect(() => {
    if (registrationCount && photoCount && dayCount) {
      onCalculate()
    }
  }, [registrationCount, dayCount, photoCount, selectedAddOns, quantities])

  function onCalculate() {
    if (!validateInputs({ registrationCount, photoCount, dayCount })) {
      return null
    }

    const newAddonsCostData = calculateAddOnsCost(
      config,
      registrationCount,
      photoCount,
      dayCount,
      quantities
    )

    const addOnsTotal = calculateTotalPrice(newAddonsCostData, selectedAddOns)
    setFinalPrice(addOnsTotal)
    setAddOnCost(newAddonsCostData)

    // scrollToElement("#price", true)
  }

  const copyToClipboard = () => {
    const tempDiv = document.createElement("div")

    const tableHtml = `
      <table style="width: 350px; border-collapse: collapse; font-size: 11px; line-height: 1;">
        <colgroup>
          <col style="width: 300px;">
          <col style="width: 100px;">
          <col style="width: 200px;">
        </colgroup>
        ${Array.from(new Set(selectedAddOns.map(item => item.group)))
          .map(
            group => `
            <tr>
              <td colspan="3" style="font-weight: bold; color: #2563eb; font-size: 11px; padding: 4px 0 2px;">
                ${PRODUCT_GROUP_DEFAULTS[group as PRODUCT_GROUP].label}
              </td>
            </tr>
            ${selectedAddOns
              .filter(item => item.group === group)
              .map(
                item => `
                <tr>
                  <td style="padding: 1px 0;">${item.label}</td>
                  <td style="text-align: center; padding: 1px 0;">${
                    item.hasQuantity ? quantities[item.priceKey] : ""
                  }</td>
                  <td style="text-align: right; padding: 1px 0;">${
                    config.currencySymbol
                  }${Math.round(addOnCost[item.priceKey]).toLocaleString(
                  "en-US"
                )}</td>
                </tr>
              `
              )
              .join("")}
          `
          )
          .join("")}
        <tr>
          <td style="font-weight: bold; padding: 12px 0 1px;">Total Cost</td>
          <td style="padding: 12px 0 1px;"></td>
          <td style="text-align: right; font-weight: bold; padding: 12px 0 1px;">${
            config.currencySymbol
          }${Math.round(finalPrice).toLocaleString("en-US")}</td>
        </tr>
        <tr>
          <td colspan="3" style="color: #666; font-size: 10px; padding: 1px 0;">*Excluding GST/VAT</td>
        </tr>
      </table>
    `

    // Set the HTML content
    tempDiv.innerHTML = tableHtml

    // Add the div to document temporarily
    tempDiv.style.position = "fixed"
    tempDiv.style.pointerEvents = "none"
    tempDiv.style.opacity = "0"
    document.body.appendChild(tempDiv)

    try {
      // Select the content
      const range = document.createRange()
      range.selectNode(tempDiv)
      const selection = window.getSelection()
      selection?.removeAllRanges()
      selection?.addRange(range)

      // Copy the content
      document.execCommand("copy")
      setCopySuccess(true)
      setTimeout(() => setCopySuccess(false), 2000)
    } catch (err) {
      console.error("Failed to copy:", err)
    } finally {
      // Clean up
      window.getSelection()?.removeAllRanges()
      document.body.removeChild(tempDiv)
    }
  }

  const ItemElement = (props: {
    title: string
    value?: number
    cost?: number
    isBold?: boolean
    addon?: AddOnConfig
  }) => (
    <div className="flex items-center py-1">
      {/* Title column - 50% width */}
      <div className="w-1/2">
        <h4 className={props.isBold ? "font-semibold mt-2" : ""}>
          {props.title}
        </h4>
      </div>

      {/* Quantity column - 25% width */}
      <div className="w-1/4 text-center">
        {props.addon?.hasQuantity ? (
          <input
            type="number"
            min="1"
            value={quantities[props.addon.priceKey]}
            onChange={e => {
              const newQty = Math.max(1, parseInt(e.target.value) || 1)
              setQuantities(prev => ({
                ...prev,
                [props.addon.priceKey]: newQty,
              }))
            }}
            step={1}
            className="w-16 px-2 py-1 border rounded text-sm text-center"
          />
        ) : props.value ? (
          <h4 className={`${props.isBold ? "font-semibold mt-2" : ""}`}>
            {props.value}
          </h4>
        ) : null}
      </div>

      {/* Cost column - 25% width */}
      <div className="w-1/4 text-right">
        {props.cost && (
          <h4 className={`${props.isBold ? "font-semibold mt-2" : ""}`}>
            {config.currencySymbol}
            {Math.round(props.cost).toLocaleString("en-US")}
          </h4>
        )}
      </div>
    </div>
  )

  return (
    <div style={{ backgroundColor: "#7251F4" }}>
      <div className="mx-auto py-20 w-full md:w-[1080px]">
        <div className="md:flex">
          <div className="w-full md:px-0 px-10 md:w-1/2">
            <h1 className="text-white font-semibold text-3xl">
              Pay Per Event - Pricing Calculator
            </h1>
            <p className="text-white mt-2 text-sm mb-8">
              This pricing is recommended for multiple-day events.
            </p>
            <div className={`mb-5 ${formGroup}`}>
              <p className="text-white font-semibold text-base mb-1">
                Total number of Attendees
                <span className="text-red-400 ml-1">*</span>
              </p>
              <input
                required
                type="number"
                name="registration_count"
                autoFocus
                placeholder="eg: 600"
                onChange={e => setRegistrationCount(Number(e.target.value))}
                className={input}
                value={registrationCount}
              />
            </div>
            <div className="flex gap-4">
              <div className={`mb-5 ${formGroup} flex-1`}>
                <p className="text-white font-semibold text-base mb-1">
                  Total number of Photos
                  <span className="text-red-400 ml-1">*</span>
                </p>
                <input
                  required
                  type="number"
                  name="photos_count"
                  placeholder="eg: 1500"
                  onChange={e => setPhotoCount(Number(e.target.value))}
                  className={input}
                  value={photoCount}
                />
              </div>
              <div className={`mb-5 ${formGroup} flex-1`}>
                <p className="text-white font-semibold text-base mb-1">
                  Number of Days<span className="text-red-400 ml-1">*</span>
                </p>
                <input
                  required
                  type="number"
                  name="days_count"
                  placeholder="eg: 3 Days"
                  defaultValue={1}
                  onChange={e => setDayCount(Number(e.target.value))}
                  className={input}
                />
              </div>
            </div>
            <div className={`mb-5 ${formGroup}`}>
              <p className="text-white font-semibold text-base mb-1">
                Features
              </p>
              <Select
                defaultValue={addOnsConfig}
                isMulti
                name="colors"
                options={Object.values(PRODUCT_GROUP).map(group => ({
                  label: PRODUCT_GROUP_DEFAULTS[group].label,
                  options: addOnsConfig
                    .filter(addon => addon.group === group)
                    .map(addon => ({
                      ...addon,
                      label: `${addon.icon} ${addon.label}`,
                    })),
                }))}
                classNamePrefix="select"
                onChange={selected => setSelectedAddOns(selected)}
              />
            </div>
          </div>
          <div
            className="w-full md:w-1/2 flex flex-col md:justify-center items-center mt-10 md:mt-0 md:px-10 px-10"
            id="price"
          >
            <div className="bg-white w-full h-full rounded-xl">
              {/* Event Details */}
              <div className="mt-4">
                <p className="text-md text-black ml-4">
                  Event with {dayCount} days with {registrationCount} Attendees
                </p>
              </div>
              {/* Pricing Table */}
              {selectedAddOns.length ? (
                <div>
                  <table className="w-full">
                    <tbody>
                      {Array.from(
                        new Set(selectedAddOns.map(item => item.group))
                      ).map(group => (
                        <React.Fragment key={group as string}>
                          <tr>
                            <td colSpan={3} className="pt-4 pb-2 pl-4">
                              <h5 className="text-xs font-semibold text-blue-600">
                                {
                                  PRODUCT_GROUP_DEFAULTS[group as PRODUCT_GROUP]
                                    .label
                                }
                              </h5>
                            </td>
                          </tr>
                          {selectedAddOns
                            .filter(item => item.group === group)
                            .map(item => (
                              <tr
                                key={item.value}
                                className="hover:bg-gray-50 transition-colors duration-150 h-8"
                              >
                                <td className="py-1 pl-4">{item.value}</td>
                                <td className="text-center">
                                  {item.hasQuantity ? (
                                    <input
                                      type="number"
                                      min="1"
                                      value={quantities[item.priceKey]}
                                      onChange={e => {
                                        const newQty = Math.max(
                                          1,
                                          parseInt(e.target.value) || 1
                                        )
                                        setQuantities(prev => ({
                                          ...prev,
                                          [item.priceKey]: newQty,
                                        }))
                                      }}
                                      step={1}
                                      className="w-16 px-2 py-1 border rounded text-sm text-center hover:border-blue-400 focus:border-blue-500 focus:ring-1 focus:ring-blue-500 focus:outline-none"
                                    />
                                  ) : null}
                                </td>
                                <td className="text-right pr-4">
                                  {config.currencySymbol}
                                  {Math.round(
                                    addOnCost[item.priceKey]
                                  ).toLocaleString("en-US")}
                                </td>
                              </tr>
                            ))}
                        </React.Fragment>
                      ))}
                      <tr className="hover:bg-gray-50 transition-colors duration-150 h-8">
                        <td className="py-4 pl-4 font-semibold">Total Cost</td>
                        <td></td>
                        <td className="text-right pr-4 font-semibold">
                          {config.currencySymbol}
                          {Math.round(finalPrice).toLocaleString("en-US")}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <p className="text-xs text-zinc-500 text-right pr-4">
                    *Excluding GST/VAT
                  </p>
                </div>
              ) : null}

              {selectedAddOns.length ? (
                <div className="border-t mt-4 pt-4 px-4 pb-4 flex items-center justify-between">
                  <button
                    onClick={copyToClipboard}
                    className="flex items-center gap-2 px-4 py-2 bg-blue-50 hover:bg-blue-100 text-blue-600 rounded-md transition-colors duration-150"
                  >
                    <svg
                      className="w-4 h-4"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M8 5H6a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2v-1M8 5a2 2 0 002 2h2a2 2 0 002-2M8 5a2 2 0 012-2h2a2 2 0 012 2m0 0h2a2 2 0 012 2v3m2 4H10m0 0l3-3m-3 3l3 3"
                      />
                    </svg>
                    Copy to Clipboard
                  </button>
                  {copySuccess && (
                    <span className="text-green-600 text-sm flex items-center gap-1">
                      <svg
                        className="w-4 h-4"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M5 13l4 4L19 7"
                        />
                      </svg>
                      Copied successfully!
                    </span>
                  )}
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
