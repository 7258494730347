import { StaticImage } from "gatsby-plugin-image";

import icon1 from '../../images/svg/1.svg';
import icon2 from '../../images/svg/2.svg';
import icon3 from '../../images/svg/3.svg';
import icon4 from '../../images/svg/4.svg';

const g2LiveData =  {
  color: '#ffeadc',
  heroTitle: `Learn how G2 achieved 4x more registrations with Premagic`,
  heroImage: <StaticImage src="../../images/case-study/g2-live/hero.png" alt="" />,
  metrics: {
    title: 'Background',
    content: `<b>G2 Live</b> brings together B2B SaaS leaders, venture capitalists, and industry experts who turn market shifts into launches for growth and innovation. The event is designed to help marketing and sales leaders drive demand and revenue, offering insights on increasing efficiency and ROI.`,
    host: 'G2',
    organizer: 'G2 APAC',
    industry: 'B2B SaaS'
  },
  images: [
    {
      img: <StaticImage src="../../images/case-study/g2-live/hero-banner.png" alt="Banner" />
    },
    {
      img: <StaticImage src="../../images/case-study/g2-live/hero-banner2.png" alt="Banner2" />
    },
  ],
  gallery: <StaticImage src="../../images/case-study/g2-live/g2-gallery.png" alt="gallery" />,
  caseStudyData: [
    {
      title: 'The Challenge',
      info1: `<b>Maintaining event excitement and generating FOMO</b><br/><br/>For events like <b>G2 Live</b>, keeping attendees engaged before, during, and after the event is a major challenge. The organizers needed a way to sustain the buzz around the event and amplify its appeal to those not attending, creating the kind of FOMO (fear of missing out) that would compel greater participation and engagement.<br/><br/><b>Simplifying the registration process</b><br/><br/>Manual registration processes can create significant bottlenecks, especially for large-scale events. Long queues, delays in name verification, and manual badge printing can frustrate attendees right at the entrance. The organizers wanted to streamline this process, ensuring a smooth, efficient check-in experience that reduced wait times and minimized the risk of errors, such as duplicate entries or incorrect attendee information.<br/><br/><b>Reducing drop-off rates</b><br/><br/>Invite-only events often suffer from a high drop-off rate, where a significant percentage of registrants fail to show up. This can be attributed to a lack of social commitment or the perception that missing the event won’t have consequences. For G2 Live, this meant potentially half of the registrants might not attend, leaving the venue underutilized and the event falling short of its potential impact. The organizers needed a way to make registrants feel invested and committed to attending the event.`,
      // testimonial: {
      //   img: <StaticImage src="../../images/case-study/gitex-global/rizona.png" alt="" />,
      //   message: `<i>"With a show like <b>GITEX GLOBAL</b>, the sheer size and diversity of our audience make it challenging to drive the level of interaction we aim for. Operating on an international scale, we’re always looking for unique ways to engage audiences, boost social media activity, and maintain visitor interest year-round—especially onsite. Premagic has been instrumental in helping us achieve this seamlessly."</i>`,
      //   name: 'Rozana Noja',
      //   info: 'AVP Marketing & Partnerships'
      // },
      image:<StaticImage src="../../images/case-study/g2-live/advocacy.png" alt="Banner2" />,
    },
    {
      title: 'The Solution',
      info1: `<b>G2 Live </b> sought more than just another event technology—they wanted a solution that could transform their event from an experience into a movement.`,
      // testimonial: {
      //   img: <StaticImage src="../../images/case-study/gitex-global/shreya.png" alt="" />,
      //   message: `<i>"Photo distribution is definitely a key component in ensuring that all event attendees have a memorable experience at the show, which in turn encourages them to get more involved. Premagic made this seamless and efficient, ensuring attendees received their photos instantly and shared them widely!
      //   "</i>`,
      //    name: 'Shreya Shyamsunder',
      //   info: 'Marketing Executive <br/> Dubai World Trade Center'
      // },
      info2: `<b>Products Used:</b><br/>
      <ul>
        <li><a href="/simplify-event-registration/">Event Registration + Check-in</a></li>
        <li><a href="/smart-photo-distribution-for-seamless-events/">AI-powered Photo Distribution</a></li>
        <li><a href="/create-event-marketing-posters/">Event Advocacy Posters</a></li>
        </ul> <br/><br/>Premagic's innovative strategies targeted each challenge with surgical precision:<br/><br/><b>Seamless check-in experience</b> with digital IDs and onsite printing<br/><br/>We teamed up with <a href='https://ticketroot.com/'>TicketRoot</a> to make the registration process smoother. Using handheld devices integrated with TicketRoot, attendees simply scanned their faces to have their badges instantly printed. This streamlined process eliminated the need for manual name verification or check-ins, turning a chaotic bottleneck into a smooth, tech-driven experience.<br/><br/><b>Personalized event advocacy posters</b> to discourage drop-offs<br/><br/>To tackle high drop-off rates, we introduced personalized event advocacy posters. When attendees receive these posters and share them on social media, they spark a ripple effect of engagement and social proof. As their network interacts with these posts, acknowledging their attendance, it reinforces their commitment, making it much harder for them to back out.<br/><br/><b>Instant photo distribution</b> that results in UGC<br/><br/>
        Real-time photo distribution turned momentary experiences into shareable stories. Attendees became content creators, extending the event's reach far beyond its physical boundaries.`,
    },
    {
      title: 'The Results',
      info1: `What began as a strategic implementation evolved into an unexpected phenomenon. The event didn't just grow—it experienced an exponential expansion of interest and engagement.<br/><br/><b>Registration beyond capacity</b><br/><br/>The personalized "I am attending" posters became a game-changing marketing tool. By creating shareable, individualized content, <b>The posters drove event registration to an extraordinary 4x their typical numbers</b>. What was once a standard registration process transformed into a viral marketing campaign.<br/><br/>`,
      testimonial: {
        img: <StaticImage src="../../images/case-study/g2-live/kevin.png" alt="Kevin Fernandes" />,
        message: `<i>"I was very impressed by Premagic's event marketing posters. Before our event, we saw many of these posters being posted on Linkedin and I am confident that it played a pivotal role in increasing our registration numbers for the event. The visibility and engagement created a difference in our promotional efforts and brand presence. It's very easy to use and implement."</i>`,
        name: 'Kevin Fernandes',
        info: 'New Business Acquisition Manager<br/>G2 APAC'
      },
    
      gridViewData: [{
        icon: <img src={icon1} alt="" />,
        title: '4x increase in event registration',
        description: ``
      },
      {
        icon: <img src={icon2} alt="" />,
        title: 'Visible reduction <br/>in drop-off rates',
        description: ``

      },
      {
        icon: <img src={icon3} alt="" />,
        title: 'Registration time <br/> cut in half',
        description: ``

      },
      {
        icon: <img src={icon4} alt="" />,
        title: 'Unprecedented organic social media reach',
        description: ``

      }
      ],
      info2: `The generated excitement reached such unprecedented levels that the G2 team faced an unexpected challenge: they simply couldn't accommodate all interested attendees.<b> Expected attendance for G2 Live was 700, but registrations surged to 1,700—a significant improvement over past events.</b> Eventually, only 1,200 attendees (with a few drop-offs) could be permitted entry. The event went from managing registrations to strategically managing overwhelming interest.<br/><br/>They went from hoping people would attend to having to turn people away. It was a beautiful problem to have. Personalized posters played a key role in driving registrations, while the efficient check-in process kept the excitement alive from the moment attendees arrived.<br/><br/><b>User-Generated Content Magic</b><br/><br/>The unique UGC strategy fundamentally changed attendee perception. Rather than viewing the event as another conference, participants now saw it as an exclusive, must-attend experience.<b> People didn't just register—they genuinely wanted to be part of the event</b>, driven by the compelling narrative created through user-generated content.<br/><br/><b>Streamlined Registration</b><br/><br/>Face check-in technology revolutionized the registration process, <b>cutting wait times in half</b>. What was previously an administrative bottleneck became a smooth, technology-enabled entry experience that set a positive tone for the entire event.`
    },
    {
      title: 'The Future',
      info1: `The team emerged from this experience with a clear mandate: prepare for scale. The unexpected exponential rise in registration—which forced them to turn away potential attendees—became a strategic opportunity for future events.`,
      // testimonial: {
      //   img: <StaticImage src="../../images/case-study/stepconference/avatar.png" alt="" />,
      //   message: `<i>"To my fellow marketers, Premagic can really boost your organic presence and engagement during and after the event. I’d highly recommend it for any event business.”</i>`,
      //   name: 'Dana Sakr',
      //   info: 'Marketing Manager <br/> Step Conference'
      // },
      info2: `Moving forward, they plan to develop robust event capacity strategies, fully utilize Premagic's powerful engagement tools, adopt more sophisticated marketing approaches, and enhance their infrastructure to accommodate growing interest.`
    }
  ],
  successMetrics:{
    title: 'Event Advocates <br/> Via Event Marketing Posters',
    description:'',
    gridValues: [
      { id: 1, label: "Advocates", value: "103" },
      { id: 2, label: "Referrals", value: "796" },
      { id: 3, label: "ROI", value: "$5,304" },
    ],
  },
}



export default g2LiveData;