import { Link } from "gatsby";
import { BlackArrowRight } from "../ds/Shapes";

export function Dropdown({data, isCaseStudy = false}) {
  return (
    <div className={`w-fit mx-auto rounded-xl shadow-2xl border border-slate-100 transition opacity-0	group-hover:opacity-100 invisible group-hover:visible absolute top-[85px] group-hover:translate-y-0 translate-y-2 mt-2 -left-40 -right-40 bg-white z-10 overflow-hidden transition duration-300 ease-in-out`}>
    <div className="container mx-auto">
      <div className='p-8'>
        <div className="flex space-between -ml-4">
          {Array.from({ length: (data.length < 6 ? 2:3) }).map((_, colIndex) => (
            <div key={colIndex} className={`w-1/${(data.length < 6? 2:3)}`}>
              {data.slice(colIndex * (data.length < 6 ? 3 :5), colIndex * (data.length < 6 ? 3: 5) + (data.length < 6 ? 3:5)).map((menu, index) => (
                <Link key={index} className='text-sm text-black rounded-xl p-4 block transition delay-10 duration-200 ease-in-out hover:bg-rose-50' to={menu.to}>
                  <h4 className="text-base text-black font-semibold mb-2">{menu.title}</h4>
                  <p className="text-xs text-zinc-500 h-8 font-normal">{menu.description}</p>
                </Link>
              ))}
            </div>
          ))}
        </div>
       {isCaseStudy && <Link to="/case-study/" className='flex items-center py-2 block mt-3'>
          <span className='mr-2 text-sm font-semibold text-black'>View all</span>
          <BlackArrowRight className='transition delay-100 duration-300 ease-in-out' />
        </Link>}
      </div>
    </div>
  </div>
);
}

