import { StaticImage } from "gatsby-plugin-image"

const AiIntegration = () => {
  return (
    <div className="pm-container flex justify-between items-center flex-col md:flex-row py-10">
      <div className="md:w-1/2">
        <h2 className="font-bold mb-6 text-2xl md:text-4xl" style={{ color: "#7252F0" }}>Integrate, <br /> automate, elevate</h2>
        <p className="md:text-md md:w-[75%] mb-10 md:mb-0 text-sm">Once you’ve experienced an event powered by Premagic, there’s no going back. We prioritize seamless integration with your go-to event software, CRMs, and marketing platforms —all at no extra cost.</p>
      </div>
      <div className="relative">
      <StaticImage
        className="relative z-10"
        src="../../images/Integration-diagram.svg"
        width={700}
        alt="Skopein, Open AI, Claude, Hubspot, MS Dynamics, Salesforce, Swapcard, Luma, CVent, Stova, Google Drive, Dropbox, CSV Import/export, Vimeo, Youtube, Any video hosting platforms, iframe embeds, Google Analytics, Facebook Pixel"
      />
      <div
        className="absolute inset-0  right-[20px] md:right-[35px] top-[10px] md:top-[20px] m-auto aspect-square w-[80%] max-w-[450px] rounded-full border-2 border-t-transparent border-dashed -z-4 border-[#C8BBFF] animate-rotateBackForth"
      ></div>
      </div>
    </div>
  )
}

export default AiIntegration