export enum PRODUCT_GROUP {
  PRE_EVENT = "PRE_EVENT",
  DURING_EVENT = "DURING_EVENT",
  POST_EVENT = "POST_EVENT",
  ADDONS = "ADDONS",
  PHOTOGRAPHY = "PHOTOGRAPHY",
}
export const PRODUCT_GROUP_DEFAULTS = {
  [PRODUCT_GROUP.PRE_EVENT]: {
    label: "Pre-event",
  },
  [PRODUCT_GROUP.DURING_EVENT]: {
    label: "During-event",
  },
  [PRODUCT_GROUP.POST_EVENT]: {
    label: "Post-event",
  },
  [PRODUCT_GROUP.ADDONS]: {
    label: "Add-ons",
  },
  [PRODUCT_GROUP.PHOTOGRAPHY]: {
    label: "Photography",
  },
}
export enum PRICING_KEY {
  QRCODEDESIGNCOST = "qrCodeDesignCost",
  QRCODEPRINTINGCOST = "qrCodePrintingCost",
  EVENTADVOCACYCOST = "eventAdvocacyCost",
  SPONSORSHIPCOST = "sponsorShipCost",
  RIPPLECOST = "rippleCost",
  DAMCOST = "damCost",
  PHOTOGRAPHERCOST = "photoGrapherCost",
  SPARKCOST = "sparkCost",
  PHOTODISTRIBUTIONCOST = "photoDistributionCost",
  REGISTRATIONMODULECOST = "registrationModuleCost",
  FACECHECKINCOST = "faceCheckinCost",
  AVATAR = "avatar",
  EVENT_SETUP_COST = "eventSetupCost",
  PROMOTER = "promoter",
  SPOT_EDITOR = "spotEditor",
}

export const initialAddonsCostData: Record<PRICING_KEY, number> = {
  [PRICING_KEY.QRCODEDESIGNCOST]: 0,
  [PRICING_KEY.QRCODEPRINTINGCOST]: 0,
  [PRICING_KEY.EVENTADVOCACYCOST]: 0,
  [PRICING_KEY.SPONSORSHIPCOST]: 0,
  [PRICING_KEY.RIPPLECOST]: 0,
  [PRICING_KEY.DAMCOST]: 0,
  [PRICING_KEY.PHOTOGRAPHERCOST]: 0,
  [PRICING_KEY.PHOTODISTRIBUTIONCOST]: 0,
  [PRICING_KEY.REGISTRATIONMODULECOST]: 0,
  [PRICING_KEY.FACECHECKINCOST]: 0,
  [PRICING_KEY.AVATAR]: 0,
  [PRICING_KEY.EVENT_SETUP_COST]: 0,
  [PRICING_KEY.SPARKCOST]: 0,
  [PRICING_KEY.PROMOTER]: 0,
  [PRICING_KEY.SPOT_EDITOR]: 0,
}

export interface AddOnConfig {
  label: string
  value: string
  priceKey: PRICING_KEY
  group: PRODUCT_GROUP
  icon: string
  hasQuantity?: boolean
  calculateCost: (params: {
    config: PricingConfig
    registrationCount: number
    photoCount: number
    dayCount: number
    quantity?: number
  }) => number
}

export const addOnsConfig: AddOnConfig[] = [
  {
    label: "Registration module",
    value: "Registration module",
    priceKey: PRICING_KEY.REGISTRATIONMODULECOST,
    group: PRODUCT_GROUP.PRE_EVENT,
    icon: "📝",
    calculateCost: ({ config, registrationCount }) =>
      registrationCount *
      config.addOnRates[PRICING_KEY.REGISTRATIONMODULECOST] *
      config.constants.finalMultiplier,
  },
  {
    label: "Face Check-in",
    value: "Face Check-in",
    priceKey: PRICING_KEY.FACECHECKINCOST,
    group: PRODUCT_GROUP.PRE_EVENT,
    icon: "👤",
    calculateCost: ({ config, registrationCount }) =>
      registrationCount *
      config.addOnRates[PRICING_KEY.FACECHECKINCOST] *
      config.constants.finalMultiplier,
  },
  {
    label: "Event advocacy posters",
    value: "Event advocacy posters",
    priceKey: PRICING_KEY.EVENTADVOCACYCOST,
    group: PRODUCT_GROUP.PRE_EVENT,
    icon: "🎨",
    calculateCost: ({ config, registrationCount }) =>
      registrationCount *
      config.addOnRates[PRICING_KEY.EVENTADVOCACYCOST] *
      config.constants.finalMultiplier,
  },
  {
    label: "Event setup",
    value: "Event setup",
    priceKey: PRICING_KEY.EVENT_SETUP_COST,
    group: PRODUCT_GROUP.PRE_EVENT,
    icon: "🎪",
    calculateCost: ({ config }) =>
      config.addOnRates[PRICING_KEY.EVENT_SETUP_COST],
  },
  {
    label: "AI based photo distribution",
    value: "AI based photo distribution",
    priceKey: PRICING_KEY.PHOTODISTRIBUTIONCOST,
    group: PRODUCT_GROUP.DURING_EVENT,
    icon: "🤖",
    calculateCost: ({ config, registrationCount, photoCount, dayCount }) => {
      const {
        dolarValue,
        whatsappRate,
        scanSearchMultiplier,
        photoscanRate,
        storageRate,
        fixedCostBase,
        cogsMultiplier,
        marginPercent,
        finalMultiplier,
      } = config.constants

      const whatsapp = registrationCount * 3 * whatsappRate * 1.2
      const scanSearch =
        (registrationCount +
          fixedCostBase * 0.2 * (((dayCount + 1) * (dayCount + 2)) / 2) +
          registrationCount * 3) *
        scanSearchMultiplier

      const photoscan = photoCount * photoscanRate
      const storage = photoCount * storageRate * 25
      const fixedcost = dolarValue
        ? (fixedCostBase * dayCount) / dolarValue
        : fixedCostBase * (dayCount + 1)

      const cogs =
        cogsMultiplier *
        (whatsapp + scanSearch + photoscan + storage + fixedcost)
      const totalCost = (cogs / (1 - marginPercent / 100)) * finalMultiplier

      return Math.max(
        Math.round(totalCost),
        config.addOnRates[PRICING_KEY.PHOTODISTRIBUTIONCOST]
      )
    },
  },
  {
    label: "Sponsorship module",
    value: "Sponsorship module",
    priceKey: PRICING_KEY.SPONSORSHIPCOST,
    group: PRODUCT_GROUP.DURING_EVENT,
    icon: "🤝",
    calculateCost: ({ config, registrationCount }) =>
      registrationCount *
      0.4 *
      config.addOnRates[PRICING_KEY.SPONSORSHIPCOST] *
      config.constants.finalMultiplier,
  },
  {
    label: "Ripple - Networking module",
    value: "Ripple - Networking module",
    priceKey: PRICING_KEY.RIPPLECOST,
    group: PRODUCT_GROUP.DURING_EVENT,
    icon: "🔄",
    calculateCost: ({ config, registrationCount, dayCount }) =>
      Math.max(
        registrationCount *
          dayCount *
          0.5 * // 50% of the registration count
          25 * // 25 request of connection
          0.01 * // cost of each request
          config.constants.finalMultiplier,
        config.addOnRates[PRICING_KEY.RIPPLECOST]
      ),
  },
  {
    label: "Avatar Studio",
    value: "Avatar Studio",
    priceKey: PRICING_KEY.AVATAR,
    group: PRODUCT_GROUP.DURING_EVENT,
    icon: "🎭",
    calculateCost: ({ config, registrationCount }) =>
      registrationCount *
      config.addOnRates[PRICING_KEY.AVATAR] *
      config.constants.finalMultiplier,
  },
  {
    label: "Sparks - Memories",
    value: "Sparks - Memories",
    priceKey: PRICING_KEY.SPARKCOST,
    group: PRODUCT_GROUP.POST_EVENT,
    icon: "✨",
    calculateCost: ({ config, registrationCount }) =>
      ((registrationCount * (3 * 0.05 + config.constants.whatsappRate)) / 0.8) *
      config.constants.finalMultiplier,
  },
  {
    label: "Event Assets Library",
    value: "Event Assets Library",
    priceKey: PRICING_KEY.DAMCOST,
    group: PRODUCT_GROUP.POST_EVENT,
    icon: "📚",
    calculateCost: ({ config, registrationCount, photoCount }) =>
      config.constants.dolarValue
        ? (((registrationCount * 1.2 * 2 * photoCount) / 1000 / 1.2) *
            config.constants.finalMultiplier *
            2) /
          config.constants.dolarValue
        : (registrationCount * 1.2 * 2 * photoCount) / 1000,
  },
  {
    label: "Custom QR code design",
    value: "Custom QR code design",
    priceKey: PRICING_KEY.QRCODEDESIGNCOST,
    group: PRODUCT_GROUP.ADDONS,
    icon: "📱",
    calculateCost: ({ config }) =>
      config.addOnRates[PRICING_KEY.QRCODEDESIGNCOST],
  },
  {
    label: "QR code printing",
    value: "QR code printing",
    priceKey: PRICING_KEY.QRCODEPRINTINGCOST,
    group: PRODUCT_GROUP.ADDONS,
    icon: "🖨️",
    calculateCost: ({ config }) =>
      config.addOnRates[PRICING_KEY.QRCODEPRINTINGCOST],
  },
  {
    label: "Photographer",
    value: "Photographer",
    priceKey: PRICING_KEY.PHOTOGRAPHERCOST,
    group: PRODUCT_GROUP.PHOTOGRAPHY,
    icon: "📸",
    hasQuantity: true,
    calculateCost: ({ config, quantity = 1 }) =>
      config.addOnRates[PRICING_KEY.PHOTOGRAPHERCOST] * quantity,
  },
  {
    label: "Promoter",
    value: "Promoter",
    priceKey: PRICING_KEY.PROMOTER,
    group: PRODUCT_GROUP.ADDONS,
    icon: "👥",
    hasQuantity: true,
    calculateCost: ({ config, quantity = 1 }) =>
      config.addOnRates[PRICING_KEY.PROMOTER] * quantity,
  },
  {
    label: "Spot Editor",
    value: "Spot Editor",
    priceKey: PRICING_KEY.SPOT_EDITOR,
    group: PRODUCT_GROUP.PHOTOGRAPHY,
    icon: "🎯",
    hasQuantity: true,
    calculateCost: ({ config, quantity = 1 }) =>
      config.addOnRates[PRICING_KEY.SPOT_EDITOR] * quantity,
  },
]

export interface PricingInputs {
  registrationCount: number
  photoCount: number
  dayCount: number
}

export const validateInputs = (inputs: PricingInputs): boolean => {
  if (!inputs.registrationCount || !inputs.photoCount || !inputs.dayCount) {
    alert("Please fill the required fields.")
    return false
  }
  return true
}

export const calculateTotalPrice = (addOnCost: any, selectedAddOns: any[]) => {
  return selectedAddOns.length
    ? selectedAddOns
        .map(item => addOnCost[item.priceKey])
        .reduce((total, price) => total + price)
    : 0
}

export interface PricingConfig {
  currency: string
  currencySymbol: string
  constants: {
    dolarValue?: number
    whatsappRate: number
    scanSearchMultiplier: number
    photoscanRate: number
    storageRate: number
    fixedCostBase: number
    cogsMultiplier: number
    marginPercent: number
    finalMultiplier: number
  }
  addOnRates: Record<PRICING_KEY, number>
}

export const US_CONFIG: PricingConfig = {
  currency: "USD",
  currencySymbol: "$",
  constants: {
    dolarValue: 85.89,
    whatsappRate: 0.095966,
    scanSearchMultiplier: 0.0013,
    photoscanRate: 0.0013,
    storageRate: 0.00003432097435,
    fixedCostBase: 500,
    cogsMultiplier: 1.1,
    marginPercent: 55,
    finalMultiplier: 1.4,
  },
  addOnRates: {
    [PRICING_KEY.QRCODEDESIGNCOST]: 250,
    [PRICING_KEY.QRCODEPRINTINGCOST]: 200,
    [PRICING_KEY.EVENTADVOCACYCOST]: 1,
    [PRICING_KEY.SPONSORSHIPCOST]: 1,
    [PRICING_KEY.RIPPLECOST]: 300,
    [PRICING_KEY.REGISTRATIONMODULECOST]: 0.7,
    [PRICING_KEY.FACECHECKINCOST]: 1,
    [PRICING_KEY.AVATAR]: 1,
    [PRICING_KEY.EVENT_SETUP_COST]: 300,
    [PRICING_KEY.PHOTODISTRIBUTIONCOST]: 1000,
    [PRICING_KEY.DAMCOST]: 1000,
    [PRICING_KEY.SPARKCOST]: 1000,
    [PRICING_KEY.PHOTOGRAPHERCOST]: 1500,
    [PRICING_KEY.PROMOTER]: 900,
    [PRICING_KEY.SPOT_EDITOR]: 1500,
  },
}

export const IN_CONFIG: PricingConfig = {
  currency: "INR",
  currencySymbol: "₹",
  constants: {
    whatsappRate: 0.88,
    scanSearchMultiplier: 0.10855975,
    photoscanRate: 0.10855975,
    storageRate: 0.002861022949,
    fixedCostBase: 500,
    cogsMultiplier: 1.1,
    marginPercent: 80,
    finalMultiplier: 1.2,
  },
  addOnRates: {
    [PRICING_KEY.QRCODEDESIGNCOST]: 15000,
    [PRICING_KEY.QRCODEPRINTINGCOST]: 5000,
    [PRICING_KEY.EVENTADVOCACYCOST]: 40,
    [PRICING_KEY.SPONSORSHIPCOST]: 60,
    [PRICING_KEY.RIPPLECOST]: 8500,
    [PRICING_KEY.REGISTRATIONMODULECOST]: 20,
    [PRICING_KEY.FACECHECKINCOST]: 55,
    [PRICING_KEY.AVATAR]: 40,
    [PRICING_KEY.EVENT_SETUP_COST]: 15000,
    [PRICING_KEY.PHOTODISTRIBUTIONCOST]: 30000,
    [PRICING_KEY.DAMCOST]: 1000,
    [PRICING_KEY.SPARKCOST]: 1000,
    [PRICING_KEY.PHOTOGRAPHERCOST]: 15000,
    [PRICING_KEY.PROMOTER]: 5000,
    [PRICING_KEY.SPOT_EDITOR]: 15000,
  },
}

export function calculateAddOnsCost(
  config: PricingConfig,
  registrationCount: number,
  photoCount: number,
  dayCount: number,
  quantities: Record<PRICING_KEY, number> = {}
): Record<PRICING_KEY, number> {
  const params = {
    config,
    registrationCount,
    photoCount,
    dayCount,
  }

  return addOnsConfig.reduce(
    (costs, addon) => ({
      ...costs,
      [addon.priceKey]: addon.calculateCost({
        ...params,
        quantity: quantities[addon.priceKey]
      }),
    }),
    initialAddonsCostData
  )
}
