import React, { useState } from "react"
import { PricingCalculatorComponent } from "../../components/PricingCalculator"
import { US_CONFIG } from "../../utils/PricingUtils"
import { PasswordScreen } from "../../components/PasswordScreen"
import Layout from "../../components/Layout"
import Header from "../../components/Header"

export function Index() {
  const [isAuthenticated, setIsAuthenticated] = useState(false)

  if (!isAuthenticated) {
    return <PasswordScreen onSuccess={() => setIsAuthenticated(true)} />
  }

  return (
    <Layout title="Standard">
      <Header />
      <PricingCalculatorComponent config={US_CONFIG} />
    </Layout>
  )
}

export default Index
