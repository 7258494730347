import { useEffect } from "react"
import Header from "../components/Header"
import Clients from "../components/landing-page/Clients"
import Hero from "../components/landing-page/Hero"
import Layout from "../components/Layout"

import AiIntegration from "../components/AI-Integration/AI-Integration"
import CustomerFeedback from "../components/inner-page/CustomerFeedback"
import EventTypes from "../components/inner-page/EventTypes"
import MagicWithUs from "../components/inner-page/MagicWithUs"
import MainFeaturesSection from "../components/inner-page/MainFeaturesSection"
import Features from "../components/landing-page/Features"
import Recommend from "../components/landing-page/Recommend"
import { homeMainFeatures } from "../data/mainFeatureData"
import "../index.css"

// Extend the Window interface to include the PM property
declare global {
  interface Window {
    PM: {
      APP_VERSION: string | undefined;
    };
  }
}

export function Index() {
  useEffect(() => {
    window.PM = {
      APP_VERSION: process.env.NX_APP_VERSION,
    }
  }, [])

  

  return (
    <Layout
      title="Transform your attendees into passionate event marketers"
      description="An AI Platform That Turns Events into Revenue Machines."
    >
      <Header isWhiteLogo isHome/>
      <Hero />
      <Clients />
      <MainFeaturesSection features={homeMainFeatures} isHome/>
      <Recommend />
      <Features />
      <EventTypes isHome/>
      <AiIntegration/>
      <CustomerFeedback isHomeScreen/>
      <MagicWithUs />
    </Layout>
  )
}

export default Index
