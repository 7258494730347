import Flickity from "react-flickity-component"
import { animationBounceLeft } from "../ds/animation.module.css"
import { btn, btnPrimary } from "../ds/button.module.css"
import { formGroup, input, } from "../ds/form.module.css"
import {
  ArrowDownWhite,
  QuoteIconWithColor,
  WhiteArrowRight,
} from "../ds/Shapes"
import { Camera } from "../ds/SvgIcons"
import { createLead } from "../services/signupService"
import { getFormData, scrollToElement } from "../utils/BrowserUtils"
import { TESTIMONIALS } from "./landing-page/Feebacks"
import { LayoutContext } from "./layoutContext"

import { useContext, useEffect, useRef, useState } from "react"

import { COUNTRY_CODES } from "../utils/CountryCodeUtils"
import useWindowDimensions from "../utils/useWindowDimensions"

import G2Icon from "../images/svgs/g2_red.svg"
import StarRating from "../images/svgs/star_yellow.svg"
import { unSupportedDomains } from "../utils/EmailUtils"

function CountryCodeSelector() {
  const data = useContext(LayoutContext)
  const [showDropdown, toggleDropdown] = useState(false)
  const wrapperRef = useRef(null)

  const [countryCode, setCountryCode] = useState<string>()
  useEffect(() => {
    const defaultCountryCode = COUNTRY_CODES.find(
      item => item.alpha2Code === (data.userLocation?.country || "US")
    )
    setCountryCode(defaultCountryCode?.dial_code)
  }, [data.userLocation])

  function useOutsideAlerter(ref) {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          toggleDropdown(false)
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside)
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside)
      }
    }, [ref])
  }

  function onFocus() {
    toggleDropdown(true)
  }

  useOutsideAlerter(wrapperRef)

  return (
    <div className="w-36 relative">
      <input
        className={`${input} appearance-none`}
        value={countryCode}
        type="text"
        name="countryCode"
        onFocus={onFocus}
      />
      {showDropdown && (
        <div
          className="shadow-xl border-xl bg-white p-2 w-80 h-96 absolute rounded-xl -top-40 z-10 overflow-scroll"
          ref={wrapperRef}
        >
          {COUNTRY_CODES.map(item => (
            <p
            className="flex items-center justify-between text-sm py-2 px-2 font-semibold cursor-pointer hover:bg-zinc-100 rounded-xl"
            onClick={() => {
              setCountryCode(item.dial_code);
              toggleDropdown(false);
            }}
          >
            <span className="inline-block w-14 text-left">{item.dial_code}</span>
            <span className="inline-block flex-1 text-left">{item.name}</span>
          </p>
          
          ))}
        </div>
      )}

      {/* <select
          className={`${input} appearance-none`}
          name="countryCode"
          defaultValue={defaultCountryCode?.dialCode}
        >
          {COUNTY_CODES.map((item) => (
            <option
              data-countrycode={item.isoCode}
              value={item.dialCode}
              key={item.isoCode}
            >
              {item.dialCode}
            </option>
          ))}
        </select> */}
    </div>
  )
}

function TestimonialMiniCard(props) {
  const { data } = props
  return (
    <div
  className="rounded-2xl p-4 md:p-10 relative md:min-h-[268px] min-h-[370px] mx-2 flex flex-col justify-between h-full"
  style={{ backgroundColor: data?.lightestColor }}
>
  <div className="absolute left-10 -top-2">
    <QuoteIconWithColor color={data.color} />
  </div>
  <div className="top pt-6 md:pt-0">
    <div className="md:hidden mb-5 flex justify-center">{data.logo}</div>
    <p className="text-black text-sm mb-5 h-24">{data?.message}</p>
  </div>
  <div className="bottom flex items-center">
    <div className="rounded-full mr-5">{data.profile}</div>
    <div>
      <h4 className="text-sm font-extrabold text-black text-left">
        {data?.name}
      </h4>
      <p className="text-xs text-left">{data?.designation || ""}</p>
      <p className="text-xs text-left">{data?.company || ""}</p>
    </div>
    <div className="hidden md:block ml-auto">{data.logo}</div>
  </div>
</div>

  )
}

function ReviewSection() {
  const { width } = useWindowDimensions();
  const isMobileUp = width >= 768;

  const flickityOptions = {
    autoPlay: 5000,
    wrapAround: true,
    pageDots: false,
    prevNextButtons: true,
  };

  return (
      <div>
        {/* Carousel Section */}
        <div className="relative overflow-scroll no-scrollbar pt-10">
          <Flickity
            className="carousel outline-none"
            options={flickityOptions}
            reloadOnUpdate
          >
            {TESTIMONIALS.map((item, index) => (
              <TestimonialMiniCard key={index} data={item} />
            ))}
          </Flickity>
        </div>

        {/* Ratings Section */}
        <div className="flex items-center my-10">
          <img src={G2Icon} alt="G2 Icon" className="mt-1" />
          <img src={StarRating} alt="Star Rating" className="ml-3" />
          <p className="ml-2 mt-1 text-sm">4.8 ratings</p>
        </div>
      </div>
  );
}


export default function RequestDemo() {
  const [isLoading, setLoading] = useState(false)
  const [error, setErrors] = useState(null)
  const [checked, setChecked] = useState(false)

  const isBrowser = () => typeof window !== "undefined"

  const isUAEDomain = isBrowser() && window.location.host.includes("ae")

  function handleFormSubmit(e) {
    const data = getFormData(e)
    const phonereg = /^\d{9,13}$/

    if(unSupportedDomains.includes(data.email.split("@")[1])) {
      setErrors({email:`Please enter a different email. <b>${data.email.split("@")[1]}</b> emails are not allowed.`})
      return
    }
    if (
      data.name.trim().length &&
      data.phone.replaceAll(" ", "").match(phonereg) !== null
    ) {
      setErrors(null)
      setLoading(true)
      createLead({
        name: data.name.trim(),
        email: data.email,
        phone: `${data.countryCode}${data.phone.replaceAll(" ", "")}`,
        avg_events_per_day: data.avg_events_per_day,
        lead_profile: "EVENT_PLANNER",
      })
        .then(response => {
          setLoading(false)
          window.location.href = `${window.location.href}success/`
        })
        .catch(data => {
          setErrors(data.error)
          scrollToElement(".js-error", true)
          setLoading(false)
        })

      return
    }
    setErrors("Please check the details you have provided")
  }
  console.log(error)
  const { width } = useWindowDimensions()
  const isMobileUp = width >= 1080;
  const isMobile = width < 768;
  const containerWidth = isMobileUp ? "1080px" : "100%"

  

  return (
    <section
      className="flex flex-wrap flex-col md:flex-row mx-auto md:my-20 px-5 md:px-0"
      style={{ width: containerWidth }}
    >
      <div className="md:w-1/2 md:p-10 md:p-0 mt-10 md:mt-0 md:pr-16">
        <h1 className="text-black font-bold text-2xl md:text-4xl ">
          Book your <span style={{ color: "#7251F4" }}>30-minute</span>{" "}
          personalized Premagic demo today!
        </h1>
        <h4 className="text-black text-base font-medium opacity-40 mb-5 mt-5">
          Let our sales experts to take you on a whirlwind tour of how Premagic
          can maximize your event experiences.
        </h4>
        {!isMobile && <ReviewSection />}
      </div>
      <div className="md:w-1/2">
        <div
          className="px-6 md:px-10 md:px-20 py-10 rounded-3xl mt-6 md:mt-0"
          style={{ backgroundColor: "#7251F4" }}
        >
          <h1 className="text-white font-semibold text-xl mb-8">
            Start the conversation for your {!isMobile && <br />}
            business event.
          </h1>

          <form onSubmit={handleFormSubmit} id="account-lead-events">
            {error && !error?.email && (
              <div
                className="js-error bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-10"
                role="alert"
              >
                <strong className="font-bold">Oops!</strong>{" "}
                <span className="block sm:inline">{error}</span>
              </div>
            )}

            <div className={formGroup}>
              <p className="text-white font-semibold text-base mb-1">
                Company name
              </p>
              <input
                required
                type="text"
                name="name"
                autoFocus
                placeholder=""
                className={input}
              />
            </div>
            <div className={`${formGroup} mt-4`}>
              <p className="text-white font-semibold text-base mb-1">
                Work Email
              </p>
              <input
                required
                type="email"
                name="email"
                placeholder="eg. david@company.com"
                style={error?.email ? { border: "2px solid #FF6550" } : {}}
                className={input}
              />
              {error?.email && <span className="block mt-2" style={{color: "#FF6550"}} dangerouslySetInnerHTML={{__html: error?.email}}></span>}
            </div>
            <div className="mt-4">
              <p className="text-white font-semibold text-base mb-1">
                Phone number
              </p>
              <div className="flex ">
                <CountryCodeSelector />
                <div className="px-2" />
                <input
                  required
                  type="tel"
                  name="phone"
                  id="input-number"
                  placeholder="eg. 9XX XXX XXXX"
                  minLength={8}
                  maxLength={13}
                  className={input}
                />
              </div>
            </div>

            <div className={`${formGroup} mt-4 relative`}>
              <select
                name="avg_events_per_day"
                placeholder="Average events per month"
                className={`${input} appearance-none`}
                defaultValue=""
              >
                <option value="" disabled hidden>
                  Average events per month
                </option>
                <option value="1">1</option>
                <option value="2-6">2-6</option>
                <option value="7-14">7-14</option>
                <option value="15-30">15-30</option>
                <option value="31-50">31-50</option>
                <option value="51+">51+</option>
                <option value="None">None</option>
                <option value="Not Sure">Not Sure</option>
              </select>
              <div className="absolute top-1/2 right-5 -translate-y-1/2 opacity-60">
                <ArrowDownWhite />
              </div>
            </div>

            <div className="text-white mt-5 text-sm flex items-start">
              <input
                className="input mr-2 mt-1"
                type="checkbox"
                onChange={e => setChecked(e.target.checked)}
              />
              <div>
                I agree to Premagic's{" "}
                <a
                  href="https://blog.premagic.com/terms/"
                  className="text-white font-bold"
                  target="_blank"
                  rel="noreferrer"
                >
                  Terms of Service
                </a>{" "}
                and{" "}
                <a
                  href="https://blog.premagic.com/privacy/"
                  className="text-white font-bold"
                  target="_blank"
                  rel="noreferrer"
                >
                  Privacy Policy
                </a>
                .
              </div>
            </div>
            <input type='hidden' id="zc_gad" name="zc_gad" value=""/>
            <button
              id="submit_button_createlead"
              className={`${btn} ${btnPrimary} bg-white mt-10`}
              type="submit"
              disabled={!checked}
            >
              {isLoading ? `Loading...` : `Get a Demo`}
              <div className={`${animationBounceLeft} ml-5`}>
                <WhiteArrowRight />
              </div>
            </button>
          </form>
        </div>
        {isMobile && <ReviewSection />}
        <div className="flex w-full justify-end my-4">
          {!isUAEDomain && (
            <a href="https://studio.premagic.com/#signup">
              <div
                className="px-3 rounded-lg py-2 z-50 flex items-center w-max"
                style={{ backgroundColor: "#FF6550" }}
              >
                <Camera />
                <p className="font-semibold text-white text-sm ml-1">
                  For Photographers
                </p>
                <div className={`${animationBounceLeft} ml-5`}>
                  <WhiteArrowRight />
                </div>
              </div>
            </a>
          )}
        </div>
      </div>
    </section>
  )
}
