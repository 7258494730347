import { StaticImage } from "gatsby-plugin-image";
import useWindowDimensions from "../../utils/useWindowDimensions";

const eventTypes = [
  {
    icon: <StaticImage width={32} src="../../images/icons/new/1.png" alt="" />,
    title: "Conferences",
    description: "Infuse engagement with just a click",
  },
  {
    icon: <StaticImage width={32} src="../../images/icons/new/2.png" alt="" />,
    title: "Seminars & Workshops",
    description: "Upgrade the learning experiences",
  },
  {
    icon: <StaticImage width={32} src="../../images/icons/new/3.png" alt="" />,
    title: "Trade Shows & Exhibitions",
    description: "Drive leads & build connections",
  },
  {
    icon: <StaticImage width={32} src="../../images/icons/new/4.png" alt="" />,
    title: "Product Launches",
    description: "Engaging product experiences",
  },
  {
    icon: <StaticImage width={32} src="../../images/icons/new/5.png" alt="" />,
    title: "Team Building Retreats",
    description: "Build unique experiences to boost morale",
  },
  {
    icon: <StaticImage width={32} src="../../images/icons/new/6.png" alt="" />,
    title: "Award Ceremonies",
    description: "Glitz & glamour with long-lasting impact",
  },
  {
    icon: <StaticImage width={32} src="../../images/icons/new/7.png" alt="" />,
    title: "Business Dinners & Galas",
    description: "Build connections, form collaborations",
  },
  {
    icon: <StaticImage width={32} src="../../images/icons/new/8.png" alt="" />,
    title: "Networking Events",
    description: "Deliver better networking opportunities",
  },
  {
    icon: <StaticImage width={32} src="../../images/icons/new/9.png" alt="" />,
    title: "Corporate Retreats",
    description: "Recharge and bond in style",
  },
]
const homeEventTypes = [
  {
    icon: <StaticImage width={32} src="../../images/icons/new/1.png" alt="" />,
    title: "Conferences",
    description: "Industry summits, leadership forums,  training seminars",
  },
  {
    icon: <StaticImage width={32} src="../../images/icons/new/3.png" alt="" />,
    title: "Trade Shows & Exhibitions",
    description: "Consumer expos, industry fairs, art shows",
  },
  {
    icon: <StaticImage width={32} src="../../images/icons/new/4.png" alt="" />,
    title: "Internal Events",
    description: "Product launches, corporate retreats, recognition ceremonies",
  },
  {
    icon: <StaticImage width={32} src="../../images/icons/new/5.png" alt="" />,
    title: "Field Events",
    description: "Pop-ups, mobile tours, community roadshows",
  },
  {
    icon: <StaticImage width={32} src="../../images/icons/new/6.png" alt="" />,
    title: "Award Ceremonies",
    description: "Industry galas, corporate awards, sports ceremonies",
  },
]



function HomeEventTypes(){
  const { width } = useWindowDimensions()
  const isMobileUp = width >= 768
  return (
    <>
      <div className="grid md:grid-cols-2 grid-cols-2 gap-2 md:gap-3">
        {homeEventTypes.slice(0, 2).map((item, index) => (
          <div
            key={index}
            className="rounded-xl p-4 md:p-8"
            style={{ backgroundColor: "#F4F7FF" }}
          >
            {item.icon}
            <h4 className="mt-6 text-black font-semibold text-md md:text-xl">{item.title}</h4>
            <p className="text-sm text-black opacity-50 mt-2">{item.description}</p>
          </div>
        ))}
      </div>
      <div className="grid md:grid-cols-3 sm:grid-cols-1 grid-cols-2 gap-2 md:gap-3 mt-2 md:mt-4">
        {homeEventTypes.slice(2, 5).map((item, index) => (
          <div
            key={index}
            className={`rounded-xl p-4 md:p-8 ${index === homeEventTypes.slice(2, 5).length - 1 && !isMobileUp && "col-span-2"
              }`}
            style={{ backgroundColor: "#F4F7FF" }}
          >
            {item.icon}
            <h4 className="mt-6 text-black font-semibold text-md md:text-xl">{item.title}</h4>
            <p className="text-sm text-black opacity-50 mt-2">{item.description}</p>
          </div>
        ))}
      </div>
    </>
  );
};


export function EventTypes({isHome = false}) {
  const { width } = useWindowDimensions()
  const isMobileUp = width >= 768
  if (isMobileUp) {
    return (
      <div className="pm-container mx-auto px-6 my-10">
        <h1 className="text-center font-bold md:text-5xl mt-10 leading-tight text-black leading-snug capitalize">
          We are changing the game, one <br />
          event at a time
        </h1>
        <p className="text-black text-center mt-10 mb-20">
          Host Events that deliver powerful engagement and elevate
          ROI.
        </p>
        {isHome ? <HomeEventTypes /> : <div className="grid grid-cols-3 gap-3">
          {eventTypes.map(item => (
            <div
              className="rounded-xl p-8"
              style={{ backgroundColor: "#F4F7FF" }}
            >
              {item.icon}
              <h4 className="mt-6 text-black font-semibold text-xl">
                {item.title}
              </h4>
              <p className="text-sm text-black opacity-50 mt-2">
                {item.description}
              </p>
            </div>
          ))}
        </div>}
      </div>
    )
  }
  return (
    <div className="pm-container mx-auto px-6 my-10">
      <h1 className="text-center font-bold text-2xl sm:text-3xl mt-10 leading-tight text-black leading-snug">
        We are changing the game, one event at a time
      </h1>
      <p className="text-black text-base text-center mt-4 sm:mt-10 mb-6">
        Whether you want to raise brand awareness, drive revenue or engage
        attendees, Premagic being the perfect choice.
      </p>
      {isHome ? <HomeEventTypes /> : <div className="grid gap-3">
        {eventTypes.map(item => (
          <div
            className="rounded-xl p-8"
            style={{ backgroundColor: "#F4F7FF" }}
          >
            {item.icon}
            <h4 className="mt-6 text-black font-semibold text-xl">
              {item.title}
            </h4>
            <p className="text-sm text-black opacity-50 mt-2">
              {item.description}
            </p>
          </div>
        ))}
      </div>}
    </div>
  )
}

export default EventTypes
